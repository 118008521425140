import React from "react"
import './style.css'

export default () => {
    return (
        <div className="loading-view" role="status">
          <svg viewBox="25 25 50 50" className="loading">
            <circle cx="50" cy="50" r="20"></circle>
          </svg>
          <span>Een moment geduld graag.</span>
          <span>Uw materiaal wordt gegenereerd.</span>
        </div>
    )
}