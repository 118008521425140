export function SET_TYPE(type) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: 'SET_TYPE',
                payload: type
            })
            resolve(type)
        })
    }
}

export function SET_ANIMATING(animating, duration = 0) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: 'SET_ANIMATING',
                payload: animating
            })
            setTimeout(() => {
                resolve(animating)
            }, duration)
            
        })
    }
}

export function SET_VALUE(value, type) {
    return {
        type: 'SET_VALUE',
        payload: {
            value, type
        }
    }
}