import React from 'react'
import './style.css'

class Tooltip extends React.Component {
    
    state = {
        active: false
    }

    handleClick = () => {
        this.setState({active: !this.state.active })
    }

    render() {
        return (
            <React.Fragment>
                
                <div className={`tooltip-button`} onClick={this.handleClick}>
                    <div className={`tooltip-buttons ${this.state.active ? 'active' : ''}`}>
                        <span>?</span>
                        <span>X</span>
                    </div>
                </div>

                {
                    this.state.active && (
                        <div className="tooltip-text">{this.props.title}</div>
                    )
                }

            </React.Fragment>
        )       
    }
}

export default Tooltip;