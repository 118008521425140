import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
// import { createLogger } from 'redux-logger'
import rootReducer from './reducers'

// const loggerMiddleware = createLogger()

let preloadedState = {}

export default createStore(
  rootReducer,
  preloadedState,
  applyMiddleware(thunkMiddleware)
  // applyMiddleware(thunkMiddleware, loggerMiddleware)
)