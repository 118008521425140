import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

class Accessibility extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = 'Toegankelijkheid | PUUR rookvrij';
  }

  render() {
    return (
      <React.Fragment>
        <Header title="Toegankelijkheid" />

        <main>
          <div className="container container--no-padding">
            <div className="content">
              <h1 className="screen-reader">Toegankelijkheid</h1>
              <div className="intro">
                <p>
                  Het ministerie van Infrastructuur en Waterstaat wil dat iedereen, zonder of met een permanente of tijdelijke functiebeperking, haar online diensten gemakkelijk kan gebruiken. Daarom werken we continu aan het verbeteren van de digitale toegankelijkheid op deze website. In de Wet digitale overheid staat dat websites van overheden moeten voldoen aan de toegankelijkheidseisen EN 301 549/WCAG 2.1. Overheden moeten daarover verantwoording afleggen in een gepubliceerde toegankelijkheidsverklaring.
                </p>
                <p>
                  De verklaring geldt voor aaninhetdonker.nl en is opgesteld op 5-04-2024<br/>
                  <a href="https://www.toegankelijkheidsverklaring.nl/register/22844" class="external" aria-label="Toegankelijkheidsverklaring aaninhetdonker.nl (opent externe website)">Toegankelijkheidsverklaring aaninhetdonker.nl</a>
                </p>
              </div>
              <h2>Hoe wij de toegankelijkheid van deze website verbeteren</h2>
              <p>Vanuit het Ministerie van Infrastructuur en Waterstaat verbeteren wij de digitale toegankelijkheid door:</p>
              <ul>
                <li>Toegankelijkheid vanaf de start. Bij nieuwe websites letten we vanaf de start bij elke stap op de toegankelijkheid. Denk aan het ontwerp-, bouw en content.</li>
                <li>Onderzoek naar de status van de toegankelijkheid. Deskundigen onderzoeken (onderdelen van) de website op toegankelijkheid. Gevonden knelpunten lossen we waar mogelijk duurzaam op.</li>
                <li>Kennis bij medewerkers. Met een campagne voor onze medewerkers verhogen we de bewustwording over het belang van Digitale Toegankelijkheid en wat hierbij komt kijken. Voor diverse medewerkers maken wij opleidingen en e-learning beschikbaar.</li>
              </ul>
              <h2>Overzicht toegankelijkheidsverklaringen</h2>
              <p>
                In het dashboard digitoegankelijk vindt u toegankelijkheidsverklaringen van andere websites waar wij verantwoordelijkheid voor zijn. Bekijk het <a class="external" aria-label="Overzicht met verklaringen van het Ministerie van Infrastructuur en Waterstaat (opent externe website)" href="https://dashboard.digitoegankelijk.nl/organisaties/553">overzicht met verklaringen van het Ministerie van Infrastructuur en Waterstaat</a>.
              </p>
              <h2>Problemen met toegankelijkheid melden</h2>
              <p>
                Heeft u vragen of opmerkingen? Of wilt u een pagina gebruiken die niet toegankelijk is? Dan kunt u contact opnemen via ons contactformulier.
              </p>
            </div>
          </div>
        </main>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Accessibility;

