const NAMES = [];

NAMES['a7'] = {title: 'A7', fontSize: 58 };
NAMES['a6'] = {title: 'A6', fontSize: 58 };
NAMES['a5'] = {title: 'A5', fontSize: 58 };
NAMES['a4'] = {title: 'A4', fontSize: 58 };
NAMES['a3'] = {title: 'A3', fontSize: 58 };
NAMES['a0'] = {title: 'A0', fontSize: 58 };
NAMES['50_76'] = {title: '50 x 76 cm', fontSize: 58 };


export default NAMES;