import React from 'react';
import Header from '../../components/Header/';
import Footer from '../../components/Footer/';

class NotFound extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = 'Niet gevonden | PUUR rookvrij';
  }

  render() {
    return (
      <React.Fragment>
        <Header title="Niet gevonden" />

        <main>
          <div className="container container--no-padding">
            <div className="content">
              <h1>Niet gevonden</h1>
              <div className="intro">
                <p>
                  De pagina die u wilde zien of het bestand dat u wilde bekijken
                  is niet gevonden.
                </p>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </React.Fragment>
    );
  }
}

export default NotFound;
