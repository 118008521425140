import React from 'react';
import { connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import { SET_VALUE } from '../../actions/poster'
import './style.css'
class SelectInput extends React.Component {

    handleChange = (e) => {
        const { SET_VALUE } = this.props.actions;
        SET_VALUE(e.currentTarget.value, this.props.field);
    }

    getOptions = () => {
        const options = this.props.options;
        return options.map(option => <option key={option.id} value={option.id}>{option.label}</option>)
    }

    render() {
        return (
            <div>
                {
                    this.props.title && (
                        <span>{this.props.title}</span>
                    )
                }
                <select className="select-input" id="select" onChange={this.handleChange}>
                    {this.getOptions()}
                </select>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({SET_VALUE}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectInput)