const initialState = {
    type: 'a0',
    animating: false
}

export default function Selection(state = initialState, action) {
    switch (action.type) {
        case 'SET_TYPE':
            return {
                ...state,
                type: action.payload,
            }
        case 'SET_ANIMATING':
            return {
                ...state,
                animating: action.payload
            }

        default:
            return state
    }
}