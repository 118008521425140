import React from "react"
import Logo from './logo.png'
import './style.css'

class Header extends React.Component {
    render() {
        return (
            <header className="header">
                <a href="https://www.komveiligthuis.nl/">
                    <img alt="Logo Rijksoverheid - Naar de homepage van komveiligthuis.nl" src={Logo} />
                </a>

                <div className="breadcrumbs">
                    <div className="container">
                        <ul>
                            <li><a href="https://www.komveiligthuis.nl/">Kom veilig Thuis</a></li>
                            <li><a href="https://www.komveiligthuis.nl/fietsverlichting">Fietsverlichting</a></li>
                            <li><a href="https://www.komveiligthuis.nl/fietsverlichting/over-de-campagne">Over de campagne</a></li>
                            <li><span>Maak je eigen AAN in het donker poster</span></li>
                        </ul>
                    </div>
                </div>

            </header>
        )
    }
}

export default Header