import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { forceDownload, slugify } from './utils'


// export function banner(who) {
//     return new Promise((resolve, reject) => {
//         let input = document.getElementById('render');
//         html2canvas(input, {
//         }).then((canvas) => {
//             const imgData = canvas.toDataURL('image/png');
//             downloadFile(imgData, 'banner-'+slugify(who)+'.png')
//             resolve()
//         })
//     })
// }
// export function facebook(who) {
//     return new Promise((resolve, reject) => {
//         let input = document.getElementById('render');
//         html2canvas(input, {
            
//         }).then((canvas) => {
//             const imgData = canvas.toDataURL('image/png');
//             downloadFile(imgData, 'social-poster-alternatief-'+slugify(who)+'.png')
//             resolve()
//         })
//     })
// }

// export async function linkedin(who) {
//     return new Promise( async (resolve, reject) => {
//         let input = document.getElementById('render');
//         await html2canvas(input, {
            
//         }).then((canvas) => {
//             const imgData = canvas.toDataURL('image/png');
//             downloadFile(imgData,'social-poster-'+slugify(who)+'.png')
//             resolve()
//         })
//     })
// }

export function a0(where) {
    return new Promise((resolve, reject) => {
        let input = document.getElementById('render');
        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');            
            const pdf = new jsPDF('p','mm','a0');
            pdf.setDrawColor('100','100',100,100); //Force CMYK PDF
            pdf.addImage(imgData, 'JPEG',0,0,841,1189);
            let blobPDF = new Blob([ pdf.output('blob') ], { type : 'application/pdf' });
            forceDownload(blobPDF, 'AAN in het donker poster - A0 - '+slugify(where)+'.pdf')
            resolve();
        })
    })
}

export function a3(where) {
    return new Promise((resolve, reject) => {
        let input = document.getElementById('render');
        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');            
            const pdf = new jsPDF('p','mm','a3');
            pdf.setDrawColor('100','100',100,100); //Force CMYK PDF
            pdf.addImage(imgData, 'JPEG',0,0,297,420);
            let blobPDF = new Blob([ pdf.output('blob') ], { type : 'application/pdf' });
            forceDownload(blobPDF, 'AAN in het donker poster - A3 - '+slugify(where)+'.pdf')
            resolve();
        })
    })
}

export function a4(where) {
    return new Promise((resolve, reject) => {
        let input = document.getElementById('render');
        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');            
            const pdf = new jsPDF('p','mm','a4');
            pdf.setDrawColor('100','100',100,100); //Force CMYK PDF
            pdf.addImage(imgData, 'JPEG',0,0,210,297);
            let blobPDF = new Blob([ pdf.output('blob') ], { type : 'application/pdf' });
            forceDownload(blobPDF, 'AAN in het donker poster - A4 - '+slugify(where)+'.pdf')
            resolve();
        })
    })
}

export function a5(where) {
    return new Promise((resolve, reject) => {
        let input = document.getElementById('render');
        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');            
            const pdf = new jsPDF('p','mm','a5');
            pdf.setDrawColor('100','100',100,100); //Force CMYK PDF
            pdf.addImage(imgData, 'JPEG',0,0,148,210);
            let blobPDF = new Blob([ pdf.output('blob') ], { type : 'application/pdf' });
            forceDownload(blobPDF, 'AAN in het donker poster - A5 - '+slugify(where)+'.pdf')
            resolve();
        })
    })
}

export function a6(where) {
    return new Promise((resolve, reject) => {
        let input = document.getElementById('render');
        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');            
            const pdf = new jsPDF('p','mm','a6');
            pdf.setDrawColor('100','100',100,100); //Force CMYK PDF
            pdf.addImage(imgData, 'JPEG',0,0,105,148);
            let blobPDF = new Blob([ pdf.output('blob') ], { type : 'application/pdf' });
            forceDownload(blobPDF, 'AAN in het donker poster - A6 - '+slugify(where)+'.pdf')
            resolve();
        })
    })
}

export function a7(where) {
    return new Promise((resolve, reject) => {
        let input = document.getElementById('render');
        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');            
            const pdf = new jsPDF('p','mm','a7');
            pdf.setDrawColor('100','100',100,100); //Force CMYK PDF
            pdf.addImage(imgData, 'JPEG',0,0,74,105);
            let blobPDF = new Blob([ pdf.output('blob') ], { type : 'application/pdf' });
            forceDownload(blobPDF, 'AAN in het donker poster - A7 - '+slugify(where)+'.pdf')
            resolve();
        })
    })
}

export function size_50_76(where) {
    return new Promise((resolve, reject) => {
        let input = document.getElementById('render');
        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');            
            const pdf = new jsPDF({
                format: [508 * 2.8346666667, 762 * 2.8346666667]
            });
            pdf.setDrawColor('100','100',100,100); //Force CMYK PDF
            pdf.addImage(imgData, 'JPEG',0,0,508,762);
            let blobPDF = new Blob([ pdf.output('blob') ], { type : 'application/pdf' });
            forceDownload(blobPDF, 'AAN in het donker poster - 51x76 - '+slugify(where)+'.pdf')
            resolve();
        })
    })
}