import React, { Component } from 'react'
import { connect} from 'react-redux'
import './style.css'

class A0 extends Component {
    render() {
        let prefix = this.props.poster.field_prefix;
        let where = this.props.poster.field_where;
        
        if(where.length > 15) {
            if(where.split(" ").length - 1 === 1) {
                where = where.replace(' ', '<br/>')
            } 
            if(where.split("-").length - 1 >= 1 && where.split(" ").length - 1 === 0) {
                where = where.replace('-', '-<br/>')
            } 
        }

        let renderText = `${prefix} ${where}`

        return (
            <section id="a0" aria-label={`Poster ${this.props.poster.field_type} AAN in het donker`} className={`a0 poster-${this.props.poster.field_type}`} style={{'--fontRatio': this.props.poster.fontRatio, 'backgroundImage': `url(${require('../../images/' + this.props.poster.field_type + '/' + this.props.poster.field_color + '.png')})`}} >
                <div className="a0-wrapper" data-color={this.props.poster.field_color}>
                    <div ref="heading" className="heading a0-heading" id="heading">
                        <span className="calc" dangerouslySetInnerHTML={{__html: renderText.replace(/(\S+\s*){1,3}/g, "$&<br/>") }}></span>
                    </div>
                    <img alt="Logo Kom veilig Thuis" src={require('../../images/logo.svg')} className="logo" />
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        poster: state.poster
    }
}

export default connect(mapStateToProps)(A0)