import React from "react"
import Header from '../../components/Header/'
import Footer from '../../components/Footer/'
import Form from '../Form/'
import Preview from '../Preview/'

import './fonts/style.css'
import './style.css'

class PosterGenerator extends React.Component {
    render() {
        return (
            <main>
                
                <Header />

                <div className="container">
                    <div className="row">
                        <div className="column column--form">
                            <div className="form-wrap">
                                <div className="form-wrap__intro">
                                    <h1>Maak je eigen AAN in het donker poster</h1>                           
                                    <p>Met een gepersonaliseerde AAN poster motiveer je mensen in jouw plaats hun fietsverlichting aan te zetten. Want met je fietslicht aan, word je beter gezien.</p>
                                </div>
                                <div className="form-wrap__form">
                                    <Form />
                                </div>
                            </div>
                        </div>
                        <div className="column column--preview">
                            <Preview />
                        </div>
                    </div>
                    
                </div>
            
                <Footer />
            
            </main>
        )
    }
}

export default PosterGenerator