import React from 'react';
import { connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import { SET_VALUE } from '../../actions/poster'
import './style.css'

class Input extends React.Component {

    handleChange = (e) => {
        
        //Custom change event?
        if(this.props.hasOwnProperty('onChange') && typeof this.props.onChange === 'function') {
            this.props.onChange(e.currentTarget.value)
            return;
        } 

        //Default change event
        const { SET_VALUE } = this.props.actions;
        SET_VALUE(e.currentTarget.value, this.props.field)
    }

    render() {
        const { title, value, field, selected } = this.props;
        
        return (
            <div className="radio-input__wrapper">
                <input checked={selected === value} className="radio-input" id={value} type="radio" value={value} name={field} onChange={this.handleChange} />
                <label htmlFor={value}>{title}</label>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({SET_VALUE}, dispatch)
    }
}


export const RadioInput = connect(mapStateToProps, mapDispatchToProps)(Input);


class RadioGroup extends React.Component {
    render() {
        return this.props.options.map(row => {
            return <RadioInput key={row.id} title={row.label} value={row.id} field={this.props.field} selected={this.props.selected} onChange={this.props.onChange}/>
        })
    }
}

export default RadioGroup