import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import Store from './store';
import { Provider } from 'react-redux';

// import Cookiebar from 'cookiebar';

// new Cookiebar({
//     id: "cookiebar",
//     cls: "cookiebar",
//     cookie: "cookiebar",
//     content: {
//         description: "Door verder te klikken op onze website, accepteer je cookies en vergelijkbare technieken.",
//         link: "Meer informatie",
//         href: "https://www.komveiligthuis.nl/cookies",
//         button: "Accepteren",
//         more: "Test"
//     },
//     fade: {
//         type: "in",
//         ms: "500",
//         display: "block"
//     },
//     debug: 0
// });

// import {Helmet} from "react-helmet";

// const metaData = {
//     title: 'Verder kijkers postermaker',
//     description: 'Meer divers, meer succes',
//     url: window.location.origin.toString(), // window is not available on server.
//     ogImageSquare: '/media/images/og-square.jpg',
//     ogImageLandscape: '/media/images/og-landscape.jpg',
// }
ReactDOM.render(

    <Provider store={Store}>
        {/* <Helmet  
            title={ metaData.title }
            link={[
                { rel: 'canonical', content: metaData.url }
            ]}
            meta={[
            { property: 'description', content: metaData.description },
            { property: 'og:type', content: 'Website' },
            { property: 'og:title', content: metaData.title },
            { property: 'og:site_name', content: metaData.title },
            { property: 'og:description', content: metaData.description },
            { property: 'og:url', content: metaData.url },
            { property: 'og:image', content: metaData.url + metaData.ogImageSquare },
            { name: 'twitter:card', content: metaData.description },
            { name: 'twitter:title', content: metaData.title },
            { name: 'twitter:description', content: metaData.description },
            { name: 'twitter:image', content: metaData.url + metaData.ogImageLandscape },
            { name: 'twitter:image:alt', content: metaData.description },
        ]}/> */}

        <App />
    </Provider>
, document.getElementById('root'));