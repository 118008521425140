import './App.css';

import React from 'react';
import PosterGenerator from './containers/PosterGenerator/'
import Accessibility from './containers/Accessibility';
import NotFound from './containers/NotFound';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
export let _paq;


class App extends React.Component {

	componentDidMount() {
		window.trackPageLoad()
		
	}

	render() {
		return (
		  <Router>
			<Switch>
			  <Route exact path="/">
				<PosterGenerator />
			  </Route>
			  <Route exact path="/toegankelijkheid">
				<Accessibility />
			  </Route>
			  <Route component={NotFound} />
			</Switch>
		  </Router>
		);
	}
	
}

export default App