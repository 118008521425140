import React from 'react';
import { connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { TweenLite } from 'gsap'
import { SET_VALUE } from '../../actions/poster'
import A0 from '../../templates/A0/'
import A7 from '../../templates/A7/'
import './style.css';
import NAMES from '../../templates/config'

class Preview extends React.Component {

   
    componentDidUpdate() {
        this.animatePreview(this.props.selection.animating)
    }

    animatePreview(status) {
        if(status) {
            //Out
            TweenLite.to(this.refs.preview, .35, {opacity: 0})
        } else {
            //in
            TweenLite.to(this.refs.preview, .35, {opacity: 1})
        }
    }

    render() {
        let previewComponent;

        switch(this.props.selection.type) {
            case 'a7':
                previewComponent = <A7 />
                break;
            case 'a6':
                previewComponent = <A0 />
                break;
            case 'a5':
                previewComponent = <A0 />
                break;
            case 'a4':
                previewComponent = <A0 />
                break;
            case 'a3':
                previewComponent = <A0 />
                break;
            case 'a0':
                previewComponent = <A0 />
                break;
            case '50_76':
                previewComponent = <A7 />
                break;
       
            default:
                break;    
        }

        return (
            <React.Fragment>
                <div ref="preview" className={`preview-holder`}>
                    <div id="preview" className="preview">
                        {previewComponent}
                    </div>
                    <span className="preview-type">{NAMES[this.props.selection.type].title}</span>
                </div>

                <div id="render" ref="render" className="render">
                    {previewComponent}
                </div>
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => {

	return {
        selection: state.selection
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({SET_VALUE}, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Preview);