import React from 'react'
import { connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import { SET_TYPE, SET_ANIMATING } from '../../actions/poster'
import TextInput from '../../components/TextInput/'
import RadioGroup from '../../components/RadioInput/'
import SelectInput from '../../components/SelectInput/'
import Tooltip from '../../components/Tooltip/'
import Loader from '../../components/Loader/'
import * as generate from '../../generate'
import {resizeHeading} from '../../resize'
import NAMES from '../../templates/config'
import './style.css'



class App extends React.Component {

	state = {
		loading: false
	}


	componentDidUpdate () {
		if( document.getElementById('heading') ){
			resizeHeading(document.querySelectorAll('.heading'))
		}
	}

	handleSubmit = () => {
		window.trackGenerate(NAMES[this.props.selection.type].title, this.props.poster.field_type, this.props.poster.field_prefix, this.props.poster.field_where, this.props.poster.field_color)
		this.setState({
			loading: true
		})
		if(this.props.selection.type === '50_76') {
			generate.size_50_76(`${this.props.poster.field_prefix} ${this.props.poster.field_where}`).then(() => {
				setTimeout(() => {
					this.setState({loading: false})
				}, 1000)
			})
		} else {
			generate[this.props.selection.type](`${this.props.poster.field_prefix} ${this.props.poster.field_where}`).then(() => {
				setTimeout(() => {
					this.setState({loading: false})
				}, 1000)
			})
		}
	}

	handleTypeChange = (data) => {
		this.props.actions.SET_ANIMATING(true, 350).then((test) => {
			this.props.actions.SET_TYPE(data)
			this.props.actions.SET_ANIMATING(false, 0);
		})
	}

	render() {
		return (
			<React.Fragment>

				{
					this.state.loading && (
						<Loader />
					)
				}
				
				<div className="form-row form-row--start">
					<div className="form-row__label"><span>Soort licht</span></div>
					<div className="form-row__input">
						<fieldset className="form-row__fieldset">
							<legend className="sr-only">Kies het soort fietslicht (voor- of achter)</legend>
							<RadioGroup selected={this.props.poster.field_type} field="field_type" options={[
								{id: 'voorlicht', label: 'Voorlicht'},
								{id: 'achterlicht', label: 'Achterlicht'},
							]} />
						</fieldset>
					</div>
				</div>

				<div className="form-row">
					<div className="form-row__label"><label for="field_prefix"><span>Voorzetsel</span></label></div>
					<div className="form-row__input"><TextInput id="field_prefix" limit={7} field="field_prefix"/></div>					
					<div className="form-row__meta">Vul hier uw voorzetsel in (bijv.'in')</div>
					{/* <Tooltip title="Vul hier uw voorzetsel in" /> */}

				</div>

				<div className="form-row">
					<div className="form-row__label"><label for="field_where"><span>Waar?</span></label></div>
					<div className="form-row__input"><TextInput limit={25} id="field_where" field="field_where"/></div>					
					<div className="form-row__meta">Vul hier uw plaatsnaam of locatie in</div>
					{/* <Tooltip title="Vul hier uw plaatsnaam of locatie in" /> */}

				</div>
				
				<div className="form-row">
					<div className="form-row__label"><label for="select"><span>Kleur</span></label></div>
					<div className="form-row__input">
						<SelectInput id="select" options={[
							{ id: 'blauw-paars', label: 'Blauw en paars' },
							{ id: 'blauw-groen', label: 'Blauw en groen' },
							{ id: 'groen-roze', label: 'Groen en roze' },
							{ id: 'paars-blauw', label: 'Paars en blauw' },
							{ id: 'blauw-cyaan', label: 'Blauw en cyaan' },
					
						]} field="field_color" />
					</div>
					<div className="form-row__meta">Kies een kleurencombinatie</div>

					{/* <Tooltip title="Kies een kleurencombinatie" /> */}
					
				</div>
				
				
				<div className="form-row form-row--start">
					<div className="form-row__label">Kies formaat</div>
					<fieldset className="form-row__fieldset">
						<legend className="sr-only">Dit zijn de verschillende formaten posters</legend>
						<div className="form-row__input form-row__input--cols">
						<RadioGroup onChange={this.handleTypeChange} selected={this.props.selection.type} field="type" options={[
							{id: 'a7', label: NAMES['a7'].title},
							{id: 'a6', label: NAMES['a6'].title},
							{id: 'a5', label: NAMES['a5'].title},
							{id: 'a4', label: NAMES['a4'].title},
							{id: 'a3', label: NAMES['a3'].title},
							{id: 'a0', label: NAMES['a0'].title},
							{id: '50_76', label: NAMES['50_76'].title},
						]} />
						</div>
					</fieldset>
				</div>

				<div className="form-row">
					<div className="form-row__label"></div>
					<div className="form-row__input">
						<button 
							onClick={this.handleSubmit} 
							className="form-row__submit"
							disabled={this.state.loading}
						>Download</button>
					</div>
				</div>												

			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		poster: state.poster,
		selection: state.selection
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({SET_TYPE, SET_ANIMATING}, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);