import React from 'react';
import { connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import { SET_VALUE } from '../../actions/poster'

import './style.css'

class TextInput extends React.Component {

    constructor(props) {
        super();
        this.limit = props.limit || 0
        this.state = {
            errorMessage: '',
        };
    }

    // handleKeyUp = (e) => {

    //     if(this.limit > 0 &&  this.limit < e.currentTarget.value.length) {
    //         e.currentTarget.value = e.currentTarget.value.substring(0, this.limit)
    //         return;
    //     }

    //     const { SET_VALUE } = this.props.actions;
    //     SET_VALUE(e.currentTarget.value, this.props.field);
    // }

    handleKeyUp = (e) => {
        const { SET_VALUE } = this.props.actions;
        const value = e.currentTarget.value;
        const { field, limit } = this.props;
      
        if (limit > 0 && value.length > limit) {
          const errorMessage = `Maximaal aantal karakters bereikt`;
          this.setState({ errorMessage });
        } else {
          this.setState({ errorMessage: '' });
          SET_VALUE(value, field);
        }
    };

    render() {
        return (
            <div className="text-input-group">
                {
                    this.props.title && (
                        <span>{this.props.title}</span>
                    )
                }

                {
                    this.limit > 0 && (
                        <div className="character-count">
                            <span className="character-count__input">{this.props.poster[this.props.field].length}</span>
                            <span className="character-count__max" id={`maxCount-${this.props.limit}`}>{this.props.limit}</span>
                        </div>
                    )
                }
                
                <input id={`${this.props.id}`} aria-describedby={`maxCount-${this.props.limit}`} className={`text-input ${this.limit > 0 ? 'text-input--limitted' : ''}`} type="text" onKeyUp={this.handleKeyUp} defaultValue={this.props.poster[this.props.field]} />
                {this.state.errorMessage && (
                    <div className="error-message" role="status">{this.state.errorMessage}</div>
                )}
                
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        poster: state.poster
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({SET_VALUE}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextInput)