const initialState = {
    field_prefix: 'In',
    field_where: 'Alphen aan den Rijn',
    field_color: 'blauw-paars',
    field_type: 'voorlicht',
    fontRatio: 0.835,
}

export default function Poster(state = initialState, action) {
    switch (action.type) {
        case 'SET_VALUE':
            return {
                ...state,
                [action.payload.type]: action.payload.value
            }

        default:
            return state
    }
}