import React from 'react'
import './style.css'
import { Link } from "react-router-dom";

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="footer-row">
                        <div className="footer-col" id="service">
                            <nav className="footer-nav">
                                <h2 className="footer-nav__title">Service</h2>
                                <ul className="footer-nav__ul">
                                    <li className="footer-nav__li"><a className="footer-nav__link" href="https://www.komveiligthuis.nl/contact">Contact</a></li>
                                    <li className="footer-nav__li"><a className="footer-nav__link" href="https://www.komveiligthuis.nl/sitemap">Sitemap</a></li>
                                    <li className="footer-nav__li"><a className="footer-nav__link" href="https://www.komveiligthuis.nl/archief">Archief</a></li>
                                    <li className="footer-nav__li"><a className="footer-nav__link" href="https://www.komveiligthuis.nl/help">Help</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="footer-col">
                            <nav className="footer-nav" id="over-deze-site">
                                <h2 className="footer-nav__title">Over deze site</h2>
                                <ul className="footer-nav__ul">
                                    <li className="footer-nav__li"><a className="footer-nav__link" href="https://www.komveiligthuis.nl/campagnes-bob-mono-snelheid-en-fietsverlichting">Over de campagne</a></li>
                                    <li className="footer-nav__li"><a className="footer-nav__link" href="https://www.komveiligthuis.nl/copyright">Copyright</a></li>
                                    <li className="footer-nav__li"><a className="footer-nav__link" href="https://www.komveiligthuis.nl/privacy">Privacy</a></li>
                                    <li className="footer-nav__li"><a className="footer-nav__link" href="https://www.komveiligthuis.nl/cookies">Cookies</a></li>
                                    <li className="footer-nav__li"><Link className="footer-nav__link" to="/toegankelijkheid">Toegankelijkheid</Link></li>
                                    <li className="footer-nav__li"><a className="footer-nav__link" href="https://www.komveiligthuis.nl/kwetsbaarheid-melden">Kwetsbaarheid melden</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer