let originalRatio = null;
let curElArr = [null];

export function resizeHeading(elArr) {
    if ( originalRatio === null || curElArr[0] !== elArr[0] ) { 
        originalRatio = getComputedStyle(elArr[0]).getPropertyValue('--headingRatio'); 
    }
    if( curElArr[0] !== elArr[0] ){ curElArr[0] = elArr[0] }

    let maxWidth = elArr[0].offsetWidth / 1.6;
    let spans = [].slice.call(elArr[0].querySelectorAll('.calc'));

    let headingWidth = Math.max.apply(Math, spans.map(function(o) { return o.offsetWidth; }));

    let ratio = (maxWidth / headingWidth).toFixed(3)
    let curFontSize = getComputedStyle(elArr[0]).getPropertyValue('--headingRatio');
    let newRatio = ratio * curFontSize;

    if ( headingWidth >= maxWidth) {
        for( let i = 0; i < elArr.length; i++ ){
            elArr[i].style.setProperty('--headingRatio', newRatio);
        }
    }
    if ( headingWidth < maxWidth ) { 
        if ( newRatio >= originalRatio ){
            for( let i = 0; i < elArr.length; i++ ){
                elArr[i].style.removeProperty('--headingRatio')
            }
        }else{
                for( let i = 0; i < elArr.length; i++ ){
                    elArr[i].style.setProperty('--headingRatio', newRatio)
                }
        }
    }
}
